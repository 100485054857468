import { mapActions } from 'vuex'
import SignInMixin from '@/store/module/signIn/mixin'
import CommonMixin from '@/store/module/common/mixin'
import { globalActions as authActions } from '@/store/module/auth/actionTypes'
import AccountRepository from '@/shared/http/repositories/access/account'
import profileExpensesRepository from '../../../shared/http/repositories/Business/profileExpenses'
import { toast, loading } from '@/shared/utils/UIHelper'
// import AuthRepository from '@/shared/http/repositories/access/auth'
import { LocaleService } from '../../../shared/services/locale'

export default {
  name: 'Authentication',
  mixins: [
    SignInMixin,
    CommonMixin
  ],
  data () {
    return {
      companies: [],
      fgtSenha: false,
      version: '2024.06.01.01.1',
      localidade: null

    }
  },
  mounted () {
    this.$i18n.locale = 'ptbr'
  },
  methods: {
    ...mapActions({
      signIn: authActions.SIGN_IN
    }),
    validateAndSignIn () {
      this.$validator.validateAll()
        .then((valid) => {
          if (valid) {
            this.signIn(this.credentials)
              .then(async (success) => {
                if (success) {
                  this.setCompany(this.credentials.company)
                  this.settypeexpenses(this.credentials.company.typeExpenses)
                  this.setAccountLogged()
                  this.credentials.username = null
                  this.credentials.password = null
                  this.credentials.company = null
                  this.$router.replace({ name: 'Consultas' })
                  this.$router.push({ name: 'Consultas' })
                  this.$i18n.locale = LocaleService.GetLocale()
                }
              })
              .catch((e) => {
                if (e.message === 'Not authorized') {
                  toast.error(this.$t('login.toast.InvalidCredentials'))
                } else {
                  toast.error(this.$t('login.toast.ServiceOff'))
                }
              })
          } else {
            toast.error(this.$t('login.toast.InvalidCredentials'))
          }
        })
    },
    getValidationState (fieldName) {
      const field = this.$validator.fields.find({ name: fieldName })

      if (!field) return null

      const isDirty = field.flags.dirty
      const isValid = !this.errors.has(fieldName)
      return isDirty ? isValid : null
    },
    getUserCompanies () {
      const username = this.credentials.username

      if (username) {
        AccountRepository.GetContaEmpresaAndEmpresas(username)
          .then(res => {
            const { code, data } = res.data
            if (code === 200) {
              if (data.length > 0) {
                // this.localidade = res.data.data[0].empresa.localidade
                // LocaleService.SaveLocale(this.localidade)
                // console.log(res.data.data[0].empresa.localidade === 'escl')
                // LocaleService.GetLocale(this.localidade)
                this.companies = data.map(company => {
                  return {
                    value: {
                      companyID: company.empresa.id,
                      name: company.empresa.nome,
                      zCode: company.empresa.codigoZ,
                      typeExpenses: company.tipoReembolso,
                      localidade: company.empresa.localidade
                    },
                    text: company.empresa.nome
                  }
                })
              } else {
                this.companies = []
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.companies = []
          })
      }
    },
    menuSenha () {
      this.fgtSenha = !this.fgtSenha
    },
    // validateForgetPassword () {
    //   loading.push()
    //   const username = this.credentials.username
    //   AccountRepository.ResetEmailSenha(username)
    //     .then(res => {
    //       loading.pop()
    //       toast.info('Recuperação de senha, encaminhada ao email cadastrado')
    //       this.fgtSenha = !this.fgtSenha
    //       this.credentials.username = null
    //     })
    //     .catch(() => {
    //       loading.pop()
    //       toast.error('Usuario Inválido!')
    //     })
    // },
    checkCompanyLocale () {
      const localidade = this.credentials.company.localidade
      LocaleService.SaveLocale(localidade)
      this.$i18n.locale = LocaleService.GetLocale()
    },
    validateForgetPassword () {
      loading.push()
      const username = this.credentials.username
      profileExpensesRepository.GetResetSenhaZpagFuncionario(username)
        .then(res => {
          loading.pop()
          // toast.info('Recuperação de senha, encaminhada ao email cadastrado')
          // // this.fgtSenha = !this.fgtSenha
          // // this.credentials.username = null
        })
        .catch(() => {
          loading.pop()
          toast.error('Usuario Inválido!')
        })
    }
  }
}
