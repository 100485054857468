import SeveralExpansesRepository from '../../../shared/http/repositories/Business/SeveralExpanses'
import MediaExpenseRepository from '../../../shared/http/repositories/Business/mediaExpense'
import ActionForms from '@/shared/mixins/actionsForm.js'
import sendExpensesModal from './components/sendExpensesModal/sendExpensesModal.vue'
// import expenseRepository from '../../../shared/http/repositories/business/expenses'
import miscellaneousExpenseRepository from '../../../shared/http/repositories/Business/miscellaneousExpenses'
import expensesmiscellaneousRepository from '../../../shared/http/repositories/Business/expensesmiscellaneous'
import { toast, loading } from '@/shared/utils/UIHelper'
// import expenseRepository from '../../../database/businesscommon/repositories/expenses'
import FileSelect from './FileSelect.vue'
import profileExpensesRepository from '../../../shared/http/repositories/Business/profileExpenses'
import AutocompleteZ from '@/components/forms/AutocompleteZ'
import ObraRepository from '../../../shared/http/repositories/Business/obra'

export default {
  name: 'Consultas',
  components: {
    sendExpensesModal,
    FileSelect,
    AutocompleteZ
  },
  mixins: [
    ActionForms
  ],
  mounted () {
    this.getDespesasEncaminhadas()
    this.despesasRepository = this.typeExpenses === 0 ? SeveralExpansesRepository : expensesmiscellaneousRepository
    this.progressBarColor = this.balanceUsed > 70 ? 'green' : 'red'
    if (this.typeExpenses === 0) {
      this.getDespesas()
      this.getCategoriaEnum()
      this.getTipoVeiculoEnum()
      this.getSubCategoriaEnum()
      this.SomaTotalDespesasPendentes()
      this.getFuncionarioId()
      this.getSubCategoriaVeiculoEnum()
      this.getDataAtual()
      this.getUltimaDataDespesaAprovada()
      this.checkUserCanSendExpenses()
    } else {
      this.getUltimaDataDespesaAprovada()
      this.getFuncionarioId()
      this.SomaTotalDespesasDiversos()
      this.getDespesasDiversos()
      this.getSubCategoriaEnum()
      this.getCategoriaEnumDiveros()
      this.checkUserCanSendExpensesDespesasDiversos()
    }
    this.verificarUsuarioAdiantamento()
    this.VerificaEscolhido()
    this.GetDespesasAprovadas()
  },
  data () {
    return {
      tipoArquivo: null,
      tipo: null,
      arquivosId: null,
      nomeArquivo: '',
      extencao: '',
      base64: '',
      dataLimiteMinima: null,
      ehPlacaValida: false,
      ehDataRetroativa: false,
      tempValue: 0,
      despesasRepository: null,
      value: 0,
      balanceUsed: null, // recebe a porcentagem, como 20%
      contador: 0,
      expensesDespesas: [],
      expenses: [],
      funcionarioDespesaObj: [],
      total: 0,
      valores: {},
      expenses2: {},
      expenses3: {},
      expenses4: {},
      expenses5: {},
      valorImprimido: 0,
      quantidadeImprimido: 0,
      coordinatorArray: null,
      categoriaImprimido: 0,
      subCategoriaImprimido: 0,
      IdDespesaName: null,
      dataFuncionariosEdit: [],
      obsImprimido: '',
      dataCategoria: [],
      dataTipoVeiculo: [],
      dataSubCategoria: [],
      dataSubCategoriaVeiculo: [],
      dataSubCategoriaCombustivel: [this.$t('options.subcategoryFuel.fuel'), this.$t('options.subcategoryFuel.diesel'), this.$t('options.subcategoryFuel.ethanol')],
      despesaId: '',
      codigoDespesa: null,
      subCategoriaDescri: '',
      subCategoriaVeiculoDescri: '',
      CategoriaDescri: '',
      obraID: '',
      formAddDe: {
        id: null,
        quantidade: 1,
        ArquivoId: null,
        valor: null,
        categoria: null,
        subCategoria: null,
        subcategoriaVeiculo: null,
        litros: null,
        litrosOleo: null,
        modelo: null,
        tipoCombustivel: null,
        placa: null,
        dataRegistroDespesa: null,
        tipoVeiculo: null,
        codigoDespesa: null,
        observacao: null,
        nomeFuncionario: null,
        motivoRecusa: null,
        dataMotivoRecusa: null,
        funcionario: [],
        selected: null,
        options: [],
        obs: null,
        file: {},
        filtro: null,
        obraId: {
          value: null,
          options: [],
          required: true,
          state: null,
          code: null
        }
      },
      file: null,
      totalTodasDespesas: 0,
      expensesTotal: 0,
      tipoFilial: [],
      filial: [],
      // typeFilialEnum: null,
      filialId: null,
      totalExpensesLimit: 0,
      balanceValue: 0,
      availableValue: 0,
      totalExpensesLimitFormatted: '',
      availableValueFormatted: '',
      expensesTotalFormatted: '',
      balanceValueFormatted: '',
      title: 'Adicionar Despesa',
      edit: false,
      dataFuncionario: [],
      coordinatorSelected: null,
      verificarSaldoFuncionario: false,
      technicianArray: [],
      coordinatorObject: {},
      autocomplete: null,
      autocompleteTecnico: null,
      obra: null,
      disabled: false,
      buttonDisabled: true,
      DisabledDesp: false,
      SearchItems: {
        nome: null
      },
      progressBarColor: '',
      progressBarColorText: '',
      funcionarioIdLogado: null,
      userCanSendExpenses: false,
      expensesHistorico: [],
      despesaEnvRecente: [],
      dataMaisRecente: null,
      balanceToSend: null,
      balanceWaitingAproved: null,
      balanceToSendFormatted: '',
      balanceWaitingAprovedFormatted: '',
      PorCentbalanceToSend: null,
      PorCentbalanceWaitingAproved: null,
      verificaDetails: false,
      testelista: null,
      selectedLocale: this.$i18n.locale,
      typesubcategoria: null,
      saldo: false,
      ImagemLocal: null,
      fileInput: null,
      edicaoFile: false,
      dataMaxima: null
    }
  },
  watch: {
    selectedLanguage: function (value) {
      if (this.selectedLanguage !== value) {
        this.$i18n.locale = value
        this.dataSearchAll = this.$t('dataSearchAll')
      }
    },
    verificaDetails: function () {
      this.ChangeColorProgressBar()
    },
    file: function (e) {
      this.fileInput = this.edit === true ? null : e
      const imagemContainer = document.getElementById('imagemContainer')
      URL.revokeObjectURL(this.ImagemLocal)
      this.ImagemLocal = null
      imagemContainer.innerHTML = ''
      if (this.fileInput != null) {
        const fileName = this.fileInput.name
        this.tipoArquivo = fileName.toLowerCase().includes('pdf')
        if (this.tipoArquivo === false) {
          if (this.fileInput.size > 0) {
            this.imagemFile = this.fileInput
            this.ImagemLocal = URL.createObjectURL(this.imagemFile)
            const imagemElemento = document.createElement('img')
            imagemElemento.src = this.ImagemLocal
            imagemContainer.innerHTML = ''
            imagemContainer.appendChild(imagemElemento)
          }
        }
      } else {
        URL.revokeObjectURL(this.ImagemLocal)
        // Limpa o contêiner da imagem
        imagemContainer.innerHTML = ''
      }
    },
    edit: function () {
      this.base64 = null
      this.tipo = null
    }
  },
  computed: {
    verificaCategoriaEnum: function () {
      if (this.typeExpenses === 0) {
        if (this.formAddDe.categoria === 0) {
          this.CategoriaDescri = 'Despesas Pessoais'
        } else if (this.formAddDe.categoria === 1) {
          this.CategoriaDescri = 'Despesas com veiculo'
        }
        // return this.CategoriaDescri
        return this.$t(`options.categories[${this.formAddDe.categoria}].text`)
      } else {
        if (this.dataCategoria.length === 0) return ''
        if (this.formAddDe.subCategoria !== null || this.formAddDe.subCategoria !== undefined) {
          const value = this.dataCategoria.find((e) => e.value === this.formAddDe.subCategoria)
          return value === undefined ? '' : this.$t(`options.subcategoriesmiscellaneous[${this.formAddDe.subCategoria}].text`)
        }
      }
    },
    verificarSendContralodoria: function () {
      if (this.typeExpenses === 1) {
        if (this.formAddDe.obraId.value != null && this.autocompleteTecnico != null) return false
        return true
      } else {
        if (this.formAddDe.obraId.value != null && this.autocomplete != null) return false
        return true
      }
    },
    dataSearchAll: function () {
      const data = []
      // this.dataSubCategoria.forEach((Element) => data.push(Element))
      this.dataSubCategoriaVeiculo.forEach((Element) => data.push(Element))
      return data
    },
    verificaEnum: function () {
      if (this.typeExpenses === 0) {
        if (this.formAddDe.categoria === 0) {
          return this.$t(`options.subcategoriestravel[${this.formAddDe.subCategoria}].text`)
        } else {
          return this.$t(`options.subcategoriaVehicle[${this.formAddDe.subcategoriaVeiculo}].text`)
        }
      } else {
        return this.$t(`options.subcategoriesmiscellaneous[${this.formAddDe.subCategoria}].text`)
      }
    }
  },
  methods: {
    edicao () {
      this.edicaoFile = true
    },
    async checkUserCanSendExpenses () {
      await SeveralExpansesRepository.CheckIfUserCanSendExpenses(this.company.companyID)
        .then((e) => {
          this.userCanSendExpenses = e.data
        })
        .catch(() => { this.userCanSendExpenses = false })
    },
    DetailsBalance () {
      this.verificaDetails = !this.verificaDetails
      this.ChangeColorProgressBar()
    },
    onSearchObra (value) {
      if (value) {
        loading.push()
        ObraRepository.GetAllByName(this.company.companyID, value, 50, 1)
          .then((response) => {
            if (response.data.code > 0) {
              this.$refs.obraRHTypeahead.data = response.data.data
            }
            loading.close()
          })
          .catch((err) => {
            console.log(err)
            loading.close()
          })
      }
    },
    onChangeCodeObra (value) {
      if (value) {
        loading.push()
        ObraRepository.GetAllByCode(this.company.companyID, encodeURIComponent(value))
          .then((response) => {
            if (response.data.code > 0) {
              if (response.data.data) {
                this.$refs.obraRHTypeahead.setValue(response.data.data[0], 'referencia')
              }
            }
            loading.close()
          }).catch(err => {
            console.log(err)
            loading.close()
          })
      }
    },

    async getUltimaDataDespesaAprovada () {
      if (this.typeExpenses === 0) {
        await SeveralExpansesRepository.GetAllDespesaEnviadas(this.company.companyID)
          .then((e) => {
            for (let index = 0; index < e.data.length - 1; index++) {
              if (e.data[index].length > 0) {
                const stringDataIndex = new Date(e.data[index].datas)
                if (this.dataMaxima < stringDataIndex) {
                  this.dataMaxima = stringDataIndex
                }
              }
            }
            this.dataLimiteMinima = this.dataMaxima
          })
      } else {
        await expensesmiscellaneousRepository.GetAllDespesaEnviadas(this.company.companyID)
          .then((Response) => {
            for (let index = 0; index < Response.data.length - 1; index++) {
              if (Response.data[index].dataDespesa.length > 0) {
                const stringDataIndex = new Date(Response.data[index].datas)
                if (this.dataMaxima < stringDataIndex) {
                  this.dataMaxima = stringDataIndex
                }
              }
            }
            this.dataLimiteMinima = this.dataMaxima
          })
      }
    },
    validarQuantidade (e) {
      const inputValue = e.target.value
      return !inputValue ? 1 : parseInt(inputValue)
    },
    async checkUserCanSendExpensesDespesasDiversos () {
      await expensesmiscellaneousRepository.CheckIfUserCanSendExpenses(this.company.companyID)
        .then((e) => {
          this.userCanSendExpenses = e.data
        })
        .catch(() => { this.userCanSendExpenses = false })
    },
    validarData (e) {
      if (e) {
        var dataInserida = new Date(e)
        dataInserida.setDate(dataInserida.getDate() + 1)
        dataInserida.setHours(0, 0, 0)
        if (this.formAddDe.dataMotivoRecusa === null || this.formAddDe.dataMotivoRecusa === '' || this.formAddDe.dataMotivoRecusa === undefined) {
          if (dataInserida < this.dataLimiteMinima) {
            this.formAddDe.dataRegistroDespesa = new Date()
            toast.error(this.$t('add_expenses.toast.expensePrevious'))
          }
          if (dataInserida.getYear() === this.dataMaisRecente.getYear()) {
            if (dataInserida.getMonth() === this.dataMaisRecente.getMonth()) {
              if (dataInserida.getDate() <= this.dataMaisRecente.getDate()) {
                this.formAddDe.dataRegistroDespesa = new Date()
                toast.error(this.$t('add_expenses.toast.expensesSended') + ` ${this.dataMaisRecente.getDate()}/${this.dataMaisRecente.getMonth() + 1}`)
              }
            }
          }
        }
        if (dataInserida > new Date()) {
          this.formAddDe.dataRegistroDespesa = new Date()
          toast.error(this.$t('add_expenses.toast.expenseFuture'))
        }
      }
    },
    validarQuantidadeDeLitros () {
      const regex = /\D/gm
      if (this.formAddDe.litros.length <= 6) {
        if (this.formAddDe.litros.match(regex)) {
          this.formAddDe.litros = this.formAddDe.litros.match(regex).forEach(x => {
            x.replace(regex, '')
          })
        }
      }
    },
    getDataAtual () {
      this.formAddDe.dataRegistroDespesa = new Date()
    },
    validarPlaca () {
      const regexPlaca = /[a-zA-Z]{3}-?[0-9][A-Za-z0-9][0-9][0-9]/gm
      if (this.formAddDe.placa.match(regexPlaca)) {
        if (this.formAddDe.placa.includes(this.formAddDe.placa.match(regexPlaca)[0])) {
          this.formAddDe.placa = this.formAddDe.placa.match(regexPlaca)[0]
        }
      }
      if (this.formAddDe.placa.match(regexPlaca) === null) {
        this.ehPlacaValida = false
        toast.error(this.$t('add_expenses.toast.validVehicle'))
      } else {
        this.ehPlacaValida = true
      }
    },
    verificarUsuarioAdiantamento () {
      loading.push()
      SeveralExpansesRepository.VerificarUsuarioAdiantamento()
        .then((Response) => {
          if (Response.data) this.verificarSaldoFuncionario = false
          else this.verificarSaldoFuncionario = true
          loading.pop()
        })
        .catch(() => loading.pop())
    },
    onLoad () {
      if (this.isEditing && this.value === 0) {
        this.value = this.editingRelease.valorUnitario
      }
    },
    // onAdd () {
    //   this.$validator.validateAll()
    //     .then(valid => {
    //       if (valid && this.value > 0) {
    //         this.$router.push({ name: 'ReimbursementAttach', params: { ...this.$route.params, value: this.value } })
    //       } else {
    //         this.toast.error(this.$lang.ReimbursementValue.Errors.ExpenseQuantityRequired)
    //       }
    //     })
    // },
    getDespesas () {
      loading.push()
      miscellaneousExpenseRepository.GetAllDespesas(this.company.companyID)
        .then(Response => {
          loading.pop()
          this.expenses = Response.data
        })
    },
    InformarFuncionarios () {
    },
    VoltarDespesa () {
      this.$refs.openModalAddDe.hide()
      this.$refs.modalone.show()
    },
    openModalAddDe () {
      this.VerificaFuncionario()
      this.$refs.modalone.hide()
      this.$refs.openModalAddDe.show()
    },
    openModalAddThird (value) {
      if (this.formAddDe.funcionario.every(x => x.id !== undefined && x.id !== null)) {
        if (this.checkCollaboratorsDuplicate(this.formAddDe.funcionario)) {
          toast.warning(this.$t('add_expenses.toast.sameEmployee'))
        } else {
          this.$refs.openModalAddDe.hide()
          this.$refs.terceiroModal.show()
          var formData = new FormData()
          formData.append('despesasDiversosDto', this.formAddDe)
          formData.append('file', this.file)
        }
      } else {
        toast.warning(this.$t('add_expenses.toast.mustfilledEmployee'))
      }
    },

    checkCollaboratorsDuplicate (collaborators) {
      const idsSet = new Set()
      for (const collaborator of collaborators) {
        if (idsSet.has(collaborator.id)) {
          return true// Elemento repetido encontrado
        }
        idsSet.add(collaborator.id)
      }
      return false // Nenhum elemento repetido encontrado
    },
    onChangeFiltro (e) {
      loading.push()
      if (this.typeExpenses === 0) {
        SeveralExpansesRepository.GetFiltroDespesa(this.company.companyID, this.formAddDe.filtro.value)
          .then((response) => {
            this.expenses = response.data
            loading.close()
          }).catch(() => {
            loading.close()
          })
      } else {
        expensesmiscellaneousRepository.GetFiltroDespesa(this.company.companyID, this.formAddDe.filtro.value)
          .then((response) => {
            this.expenses = response.data
            loading.close()
          }).catch(() => {
            loading.close()
          })
      }
    },
    onChangeCodeFuncionario (e, id) {
      this.IdDespesaName = id
      var value = e.target.value
      loading.push()
      SeveralExpansesRepository.GetFuncionarioByCodigo(value)
        .then((response) => {
          if (response.data.code > 0) {
            this.formAddDe.funcionario[this.IdDespesaName].nome = response.data.data.nome
            this.formAddDe.funcionario[this.IdDespesaName].id = response.data.data.id
            var input = document.getElementById('nome-' + this.IdDespesaName)
            input.value = response.data.data.nome
            var ob = {
              index: this.IdDespesaName,
              Id: response.data.data.id
            }
            this.dataFuncionariosEdit = this.dataFuncionariosEdit.filter((Element) => Element.index !== this.IdDespesaName)
            this.dataFuncionariosEdit.push(ob)
          }
          this.IdDespesaName = null
          loading.close()
        }).catch(() => {
          this.IdDespesaName = null
          loading.close()
        })
    },
    onChangeNameFuncionario (e, id) {
      this.IdDespesaName = id
      var value = e.target.value
      loading.push()
      SeveralExpansesRepository.GetFuncionarioByNome(value)
        .then((response) => {
          if (response.data.code > 0) {
            this.formAddDe.funcionario[this.IdDespesaName].nome = response.data.data.nome
            this.formAddDe.funcionario[this.IdDespesaName].codigo = response.data.data.codigo
            this.formAddDe.funcionario[this.IdDespesaName].id = response.data.data.id
            var input = document.getElementById('nome-' + this.IdDespesaName)
            input.value = response.data.data.nome
            var ob = {
              index: this.IdDespesaName,
              Id: response.data.data.id
            }
            this.dataFuncionariosEdit = this.dataFuncionariosEdit.filter((Element) => Element.index !== this.IdDespesaName)
            this.dataFuncionariosEdit.push(ob)
          }
          this.IdDespesaName = null
          loading.close()
        }).catch(() => {
          this.IdDespesaName = null
          loading.close()
        })
    },
    AdicionarDespesa () {
      loading.push()
      if (this.edicaoFile === false) {
        this.file = null
      }
      var formData = new FormData()
      this.formAddDe.EmpresaId = this.company.companyID
      var respositoryDespesas = this.typeExpenses === 0 ? SeveralExpansesRepository : expensesmiscellaneousRepository
      formData.append('despesasDiversosDtoJson', JSON.stringify(this.formAddDe))
      if (this.file !== null) {
        formData.append('file', this.file)
      }
      if (!this.edit) {
        respositoryDespesas.PostDespesa(this.company.companyID, formData)
          .then(Response => {
            loading.close()
            if (Response.data) {
              toast.success(this.$t('add_expenses.toast.expenseCreated'), {
                timeOut: 1500,
                fadeOut: 1500
              })
              this.despesaId = Response.data.id
              this.$refs.terceiroModal.hide()
              setTimeout(() => {
                window.location.reload()
              }, 2000)
            } else {
              toast.error(this.$t('add_expenses.toast.expenseNotcreated'))
              this.$refs.terceiroModal.hide()
            }
            this.close()
            this.$emit('reloadItems')
          }).catch((Error) => {
            this.$refs.terceiroModal.hide()
            toast.error(Error.response.data.Message)
            loading.close()
          })
      } else {
        respositoryDespesas.PutDespesas(formData)
          .then(Response => {
            loading.close()
            if (Response.data) {
              toast.success(this.$t('add_expenses.toast.expenseEdited'), {
                timeOut: 1500,
                fadeOut: 1500
              })
              this.despesaId = Response.data.id
              this.$refs.terceiroModal.hide()
              setTimeout(() => {
                window.location.reload()
              }, 2000)
            } else {
              toast.error(this.$t('add_expenses.toast.expenseNotedited'))
            }
            this.close()
            this.$emit('reloadItems')
          }).catch((Error) => {
            toast.error(Error.response.data.Message)
            loading.close()
          })
      }
    },
    VerificaFuncionario () {
      this.formAddDe.funcionario = []
      if (this.edit === false) {
        profileExpensesRepository.GetPerfil()
          .then(Response => {
            this.formAddDe.funcionario[0].codigo = Response.data[0].codigo
            this.formAddDe.funcionario[0].nome = Response.data[0].nome
            this.formAddDe.funcionario[0].id = Response.data[0].funcionarioID
          })
      }

      for (var i = 0; i < parseInt(this.formAddDe.quantidade); i++) {
        const obj = {
          id: this.dataFuncionario[i] != null ? this.dataFuncionario[i].funcionarioID : undefined,
          codigo: this.dataFuncionario[i] != null ? this.dataFuncionario[i].codigoFuncionario : undefined,
          nome: this.dataFuncionario[i] != null ? this.dataFuncionario[i].nomeFuncionario : undefined
        }
        this.formAddDe.funcionario.push(obj)
      }
    },
    calTotal (expenses) {
      let total = 0
      let totalFormatado = ''
      if (this.typeExpenses === 0) {
        expenses.despesasViagens.forEach((Element) => {
          total += Element.valor
          if (this.$i18n.locale === 'ptbr') {
            totalFormatado = total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          }
          if (this.$i18n.locale === 'escl') {
            totalFormatado = 'CLP ' + total.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          }
        })
        return totalFormatado
      } else {
        expenses.despesasDiversos.forEach((Element) => {
          total += Element.valor
          if (this.$i18n.locale === 'ptbr') {
            totalFormatado = total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          }
          if (this.$i18n.locale === 'escl') {
            totalFormatado = 'CLP ' + total.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          }
        })
        return totalFormatado
      }
    },
    GetSubCategorias (despesas) {
      var subCategorias = []
      var despesafinal = []
      despesas.forEach((Element) => {
        const verificar = subCategorias.find((ElementSub) => ElementSub === Element.subcategoria)
        if (verificar === null || verificar === undefined) {
          subCategorias.push(Element.subcategoria)
        }
      })
      subCategorias.forEach((Element) => {
        let total = 0
        despesas.forEach((a) => {
          if (a.subcategoria === Element) {
            total += a.valor
          }
        })
        var obj = {
          valor: total,
          subCategoriaDescricao: despesas.find((Elementsub) => Elementsub.subcategoria === Element).subcategoriaDescricao
        }
        despesafinal.push(obj)
      })
      return despesafinal
    },
    GetSubCategoriasVeiculos (despesas) {
      var subCategoriasVeiculos = []
      var despesafinal = []
      despesas.forEach((Element) => {
        const verificarVeiculo = subCategoriasVeiculos.find((ElementSub) => ElementSub === Element.subcategoriaVeiculo)
        if (verificarVeiculo === null || verificarVeiculo === undefined) {
          subCategoriasVeiculos.push(Element.subcategoriaVeiculo)
        }
      })
      subCategoriasVeiculos.forEach((Element) => {
        let total = 0
        despesas.forEach((a) => {
          if (a.subcategoriaVeiculo === Element) {
            total += a.valor
          }
        })
        var obj = {
          valor: total,
          subCategoriaVeiculoDescricao: despesas.find((Elementsub) => Elementsub.subcategoriaVeiculo === Element).subCategoriaVeiculoDescricao
        }
        despesafinal.push(obj)
      })
      return despesafinal
    },
    openIncluirDespesa () {
      this.VerificaEdicao()
    },
    getDespesasDiversos () {
      loading.push()
      expensesmiscellaneousRepository.GetAllDespesas(this.company.companyID)
        .then((Response) => {
          const updatedExpenses = Response.data.map(expense => ({
            ...expense,
            despesasDiversos: expense.despesasDiversos.map(item => ({
              ...item,
              subCategoriaDescricao: item.saldo === true ? this.$t('balance.title') : item.subCategoriaDescricao
            }))
          }))
          this.expenses = updatedExpenses
          this.saldo = Response.data.some((e) => (e.despesasDiversos.some((a) => a.saldo === true)))
          loading.pop()
        })
        .catch((Error) => {
          loading.pop()
        })
    },
    openEditarDespesa (obj) {
      this.edicaoFile = false
      return obj.saldo !== true ? (this.VerificaEdicao(obj.id), this.GetArquivosId(obj.id)) : toast.warning('Não é possível editar, Saldo Inicial!')
    },
    AtualizaPagina () {
      this.$router.go()
    },
    SomaTotalDespesasPendentes () {
      miscellaneousExpenseRepository.GetTotalDespesas(this.company.companyID)
        .then(Response => {
          this.expensesTotal = Response.data[0].valorTotal
          this.totalExpensesLimit = Response.data[0].valorLimiteDespesa
          this.balanceValue = Response.data[0].valorSaldo
          this.balanceUsed = Response.data[0].valorProgressBar
          this.balanceToSend = Response.data[0].valorAEncaminhar
          this.balanceWaitingAproved = Response.data[0].valorAgAprovacao
          this.PorCentbalanceToSend = Response.data[0].valorAgAprovacaoPorCent
          this.PorCentbalanceWaitingAproved = Response.data[0].valorAEncaminharPorCent

          this.expensesTotalFormatted = this.$i18n.locale === 'ptbr' ? this.expensesTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.expensesTotal.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.totalExpensesLimitFormatted = this.$i18n.locale === 'ptbr' ? this.totalExpensesLimit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.totalExpensesLimit.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceValueFormatted = this.$i18n.locale === 'ptbr' ? this.balanceValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceValue.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.availableValueFormatted = this.$i18n.locale === 'ptbr' ? this.availableValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.availableValue.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceToSendFormatted = this.$i18n.locale === 'ptbr' ? this.balanceToSend.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceToSend.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceWaitingAprovedFormatted = this.$i18n.locale === 'ptbr' ? this.balanceWaitingAproved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceWaitingAproved.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })

          this.ChangeColorProgressBar()
        })
    },
    SomaTotalDespesasDiversos () {
      expensesmiscellaneousRepository.GetTotalDespesas(this.company.companyID)
        .then(Response => {
          this.expensesTotal = Response.data[0].valorTotal
          this.totalExpensesLimit = Response.data[0].valorLimiteDespesa
          this.balanceValue = Response.data[0].valorSaldo
          this.balanceUsed = Response.data[0].valorProgressBar
          this.balanceToSend = Response.data[0].valorAEncaminhar
          this.balanceWaitingAproved = Response.data[0].valorAgAprovacao
          this.PorCentbalanceToSend = Response.data[0].valorAgAprovacaoPorCent
          this.PorCentbalanceWaitingAproved = Response.data[0].valorAEncaminharPorCent

          this.expensesTotalFormatted = this.$i18n.locale === 'ptbr' ? this.expensesTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.expensesTotal.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.totalExpensesLimitFormatted = this.$i18n.locale === 'ptbr' ? this.totalExpensesLimit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.totalExpensesLimit.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceValueFormatted = this.$i18n.locale === 'ptbr' ? this.balanceValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceValue.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.availableValueFormatted = this.$i18n.locale === 'ptbr' ? this.availableValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.availableValue.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceToSendFormatted = this.$i18n.locale === 'ptbr' ? this.balanceToSend.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceToSend.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })
          this.balanceWaitingAprovedFormatted = this.$i18n.locale === 'ptbr' ? this.balanceWaitingAproved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'CLP ' + this.balanceWaitingAproved.toLocaleString('es-cl', { currency: 'CLP', minimumFractionDigits: 2 })

          this.ChangeColorProgressBar()
        })
    },
    getCategoriaEnum () {
      SeveralExpansesRepository.GetCategoria()
        .then(Response => {
          this.dataCategoria = Response.data
          // this.dataCategoria = this.dataCategoria.filter((Element) => parseInt(Element.value) !== 2)
        })
    },
    getCategoriaEnumDiveros () {
      expensesmiscellaneousRepository.GetSubCategoriadiversos()
        .then(Response => {
          this.dataCategoria = Response.data
          // this.dataCategoria = this.dataCategoria.filter((Element) => parseInt(Element.value) !== 2)
        })
    },
    getTipoVeiculoEnum () {
      SeveralExpansesRepository.GetTipoVeiculo()
        .then(Response => {
          this.dataTipoVeiculo = Response.data
        })
    },
    validacaoBotaoAvancar () {
      if (this.formAddDe.valor < 0) {
        toast.error(this.$t('add_expenses.toast.insertValue'))
      }
      if (this.typeExpenses === 0) {
        if ((this.formAddDe.valor <= 0 || this.formAddDe.categoria == null || this.formAddDe.quantidade <= 0 || (this.formAddDe.subCategoria == null && this.formAddDe.subcategoriaVeiculo == null) || this.file == null) && this.ehPlacaValida != null) {
          return true
        } else if ((this.formAddDe.valor <= 0 && this.formAddDe.categoria === 1 && this.formAddDe.quantidade <= 0 && this.formAddDe.tipoVeiculo == null && (this.formAddDe.subCategoria == null || this.formAddDe.subcategoriaVeiculo == null) && this.file === null) && !this.ehPlacaValida) {
          return true
        } else if ((this.formAddDe.valor <= 0 && this.formAddDe.categoria === 1 && this.formAddDe.quantidade <= 0 && (this.formAddDe.subCategoria == null || this.formAddDe.subcategoriaVeiculo == null) && (this.formAddDe.litros == null || this.formAddDe.tipoCombustivel === null) && (this.formAddDe.placa === null && this.formAddDe.modelo) && this.file != null) && !this.ehPlacaValida) {
          return true
        } else {
          return false
        }
      } else {
        if ((this.formAddDe.valor <= 0 || this.formAddDe.quantidade <= 0 || this.formAddDe.subCategoria == null || this.file == null) && !this.ehPlacaValida) {
          return true
        } else {
          return false
        }
      }
    },
    verificarSubCategoria () {
      if (this.formAddDe.categoria === 1) {
        this.getSubCategoriaVeiculoEnum() // chamar o método de enum
      } else {
        this.getSubCategoriaEnum()
      }
      // this.formAddDe.tipoVeiculo = null
      // this.formAddDe.placa = null
      // this.formAddDe.modelo = null
      // this.formAddDe.subCategoria = null
      // this.formAddDe.litrosOleo = null
      // this.formAddDe.tipoCombustivel = null
      // this.formAddDe.litros = null
      // this.formAddDe.subcategoriaVeiculo = null
    },
    // GetFiliacoes () {
    //   miscellaneousExpenseRepository.GetEnumFiliacao()
    //     .then(Response => {
    //       this.tipoFilial = Response.data
    //     })
    // },
    VerificaEscolhido () {
      miscellaneousExpenseRepository.GetFiliacoes()
        .then(Response => {
          this.filial = Response.data
        })
    },
    getSubCategoriaVeiculoEnum () {
      SeveralExpansesRepository.GetSubCategoriaVeiculo()
        .then(Response => {
          this.dataSubCategoriaVeiculo = Response.data
        })
    },
    getSubCategoriaEnum () {
      this.despesasRepository.GetSubCategoria()
        .then(Response => {
          this.dataSubCategoria = Response.data
        })
    },
    checarSeEhDataRetroativa (dataString) {
      if (new Date(dataString) < new Date() && new Date(dataString) === new Date()) {
        return true
      } else {
        return false
      }
    },
    VerificaEdicao (obj = {}) {
      this.$refs.modalone.show()
      loading.push()
      if (Object.keys(obj).length !== 0) {
        this.title = 'Editar Despesa'
        this.edit = true
        var despesasRepository = this.typeExpenses === 0 ? SeveralExpansesRepository : expensesmiscellaneousRepository
        despesasRepository.GetDespesa(obj)
          .then(Response => {
            var listaFuncionario = Response.data
            listaFuncionario.forEach((valor, index) => {
              if (valor.funcionarioID === this.funcionarioIdLogado) {
                const funcionarioLogado = listaFuncionario.find((funcionario) => {
                  return funcionario.funcionarioID === this.funcionarioIdLogado
                })
                listaFuncionario.splice(index, 1)
                listaFuncionario.unshift(funcionarioLogado)
              }
            })
            this.VerificaFuncionario()
            this.ehDataRetroativa = this.checarSeEhDataRetroativa(Response.data[0].dataRegistroDespesa)
            this.formAddDe.dataRegistroDespesa = Response.data[0].dataRegistroDespesa
            this.formAddDe.motivoRecusa = Response.data[0].motivoRecusa
            this.formAddDe.dataMotivoRecusa = Response.data[0].dataMotivoRecusa
            this.dataFuncionario = listaFuncionario
            this.formAddDe.id = Response.data[0].despesaID
            this.formAddDe.quantidade = Response.data[0].quantidade
            this.formAddDe.valor = Response.data[0].valor
            this.formAddDe.tipoCombustivel = Response.data[0].tipoCombustivel
            this.formAddDe.litros = Response.data[0].litros
            this.formAddDe.litrosOleo = Response.data[0].litrosOleo
            this.formAddDe.modelo = Response.data[0].modelo
            this.formAddDe.placa = Response.data[0].placa
            this.formAddDe.categoria = this.typeExpenses === 0 ? Response.data[0].categoria : Response.data[0].subCategoria
            this.formAddDe.subCategoria = Response.data[0].subCategoria
            this.formAddDe.subcategoriaVeiculo = Response.data[0].subCategoriaVeiculo
            this.formAddDe.tipoVeiculo = Response.data[0].tipoVeiculo
            this.formAddDe.observacao = Response.data[0].observacao
            this.file = this.dataURLtoFile(Response.data[0].arquivobyte, Response.data[0].arquivoNome)
            this.$emit('input', this.file)
            loading.close()
          })
          .catch(() => {
            loading.close()
          })
      } else {
        this.title = 'Adicionar Despesa'
        this.edit = false
        this.formAddDe.dataRegistroDespesa = new Date()
        this.ehDataRetroativa = false
        this.formAddDe.quantidade = 1
        this.formAddDe.valor = 0
        this.formAddDe.categoria = null
        this.formAddDe.subCategoria = null
        this.formAddDe.tipoVeiculo = null
        this.formAddDe.observacao = null
        this.formAddDe.litros = null
        this.formAddDe.litrosOleo = null
        this.formAddDe.modelo = null
        this.formAddDe.tipoCombustivel = null
        this.formAddDe.placa = null
        this.formAddDe.motivoRecusa = null
        this.formAddDe.dataMotivoRecusa = null
        this.file = null
        this.dataFuncionario = []
        loading.close()
      }
      // this.$refs.modalone.show()
    },
    dataURLtoFile (base64, ArquivoNome) {
      var dataurl = 'data:application/pdf;base64,' + base64
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], ArquivoNome, { type: mime })
    },
    sendExpenses () {
      this.obraID = this.formAddDe.obraId.value
      this.$refs.modalExpense.hide()
      var despesasRepository = this.typeExpenses === 0 ? miscellaneousExpenseRepository : expensesmiscellaneousRepository
      loading.push()
      this.typeExpenses === 0 ? despesasRepository.SendExpenses(this.autocomplete.id, this.obraID) : despesasRepository.SendExpenses(this.obraID, this.autocompleteTecnico.id)
        .then(() => {
          loading.pop()
          this.$router.go()
          toast.success(this.$t('submitExpenses.toast.sendingExpense'))
        })
        .catch(() => {
          toast.error(this.$t('submitExpenses.toast.sendingErrorExpense'))
          loading.pop()
        })
    },
    filterName (e) {
      if (e.query.length >= 3) {
        this.disabled = true
        loading.push()
        profileExpensesRepository.GetCordinatorsByName(this.autocomplete)
          .then((response) => {
            loading.pop()
            this.coordinatorArray = response.data.data
            this.disabled = false
          })
          .catch(() => {
            loading.pop()
          })
      }
    },
    filterNameTechnician (e) {
      if (e.query.length >= 3) {
        this.disabled = true
        loading.push()
        profileExpensesRepository.GetTecnicoByName(this.autocompleteTecnico)
          .then((response) => {
            loading.pop()
            this.technicianArray = response.data.data
            this.disabled = false
          })
          .catch(() => {
            loading.pop()
          })
      }
    },
    onSearchItems (value) {
      loading.push()
      profileExpensesRepository.GetCordinatorsByName(value)
        .then((response) => {
          if (response.data.code > 0) {
            this.$refs.ConsultaTypeHead.data = response.data.data
          }
          loading.pop()
        })
        .catch(() => {
          loading.pop()
        })
    },
    closeSendExpensesModal () {
      this.$refs.modalExpense.hide()
      this.autocomplete = null
      this.disabled = false
    },
    ChangeColorProgressBar () {
      if (this.balanceUsed > 70) {
        this.progressBarColorText = 'red'
        if (this.verificaDetails === false) {
          this.progressBarColor = 'red'
        } else {
          if (this.PorCentbalanceToSend === 0) {
            this.progressBarColor = 'red'
          } else {
            this.progressBarColor = `linear-gradient(to right, red ${this.PorCentbalanceWaitingAproved}%,#de852c ${this.PorCentbalanceWaitingAproved}% ,#de852c ${this.PorCentbalanceToSend}%)`
          }
          // this.progressBarColor = `linear-gradient(to right, red ${this.PorCentbalanceWaitingAproved}%,#de852c ${this.PorCentbalanceWaitingAproved}% ,#de852c ${this.PorCentbalanceToSend}%)`
        }
      } else {
        this.progressBarColorText = 'green'
        if (this.verificaDetails === false) {
          this.progressBarColor = 'green'
        } else {
          if (this.PorCentbalanceToSend === 0) {
            this.progressBarColor = 'green'
          } else {
            this.progressBarColor = `linear-gradient(to right, green ${this.PorCentbalanceWaitingAproved}%,#de852c ${this.PorCentbalanceWaitingAproved}% ,#de852c ${this.PorCentbalanceToSend}%)`
          }
          // this.progressBarColor = `linear-gradient(to right, green ${this.PorCentbalanceWaitingAproved}%,#de852c ${this.PorCentbalanceWaitingAproved}% ,#de852c ${this.PorCentbalanceToSend}%)`
        }
      }
    },
    getFuncionarioId () {
      loading.push()
      profileExpensesRepository.GetPerfil()
        .then(Response => {
          loading.pop()
          this.funcionarioIdLogado = Response.data[0].funcionarioID
        })
        .catch(() => loading.pop())
    },

    GetArquivosId (DespesasId) {
      var respositoryDespesas = this.typeExpenses === 0 ? MediaExpenseRepository : expensesmiscellaneousRepository
      respositoryDespesas.ArquivoByDespesaId(DespesasId)
        .then((response) => {
          if (response.data.length <= 0) {
            this.arquivosId = null
          } else {
            this.arquivosId = response.data.arquivoID
            this.nomeArquivo = response.data.arquivo.name
            this.file = response.data.arquivo
            this.formAddDe.ArquivoId = response.data.arquivoID
            this.extencao = this.nomeArquivo.replace(/^.*\./, '')
          }
          this.GetBase64(this.arquivosId)
          this.este(this.extencao)
        })
    },

    este (e) {
      if (e === 'png') {
        this.tipo = 0
        return
      }
      if (e === 'JPEG') {
        this.tipo = 1
        return
      }
      if (e === 'jpg') {
        this.tipo = 3
        return
      }
      if (e === 'GIF') {
        this.tipo = 2
      } else {
        this.tipo = 4
      }
    },

    GetBase64 (arquivosId) {
      var respositoryDespesas = this.typeExpenses === 0 ? miscellaneousExpenseRepository : expensesmiscellaneousRepository
      if (this.arquivosId != null) {
        loading.push()
        if (this.typeExpenses === 0) {
          respositoryDespesas.Base64ByArquivoId(arquivosId, this.company.companyID)
            .then(Response => {
              if (Response.data) {
                this.base64 = Response.data
                loading.close()
              }
            })
            .catch((Error) => {
              toast.error(this.$t('add_expenses.toast.errorReceipt'))
              loading.close()
            })
        } else {
          respositoryDespesas.Base64ByArquivoId(arquivosId)
            .then(Response => {
              if (Response.data) {
                this.base64 = Response.data
                loading.close()
              }
            })
            .catch((Error) => {
              toast.error(this.$t('add_expenses.toast.errorReceipt'))
              loading.close()
            })
        }
      } else {
        toast.warning(this.$t('add_expenses.toast.noReceipt'))
      }
    },

    downloadComprovante () {
      if (this.base64 != null) {
        var a = document.createElement('a')
        const typefile = 'v,slv'.includes('.pdf') ? 'application/pdf' : 'image/png'
        a.href = `data:${typefile};base64,` + this.base64
        a.download = this.nomeArquivo
        a.click()
        toast.success(this.$t('add_expenses.toast.downloadReceipt'))
      } else {
        toast.warning(this.$t('add_expenses.toast.downloadErroReceipt'))
      }
    },

    preview () {
      var modal = document.getElementById('myModal')
      modal.style.display = 'block'
      var modalImg = this.file
      var captionText = this.nomeArquivo

      modal.style.display = 'block'
      modalImg.src = this.src
      captionText.innerHTML = this.nomeArquivo
    },

    close () {
      var modal = document.getElementById('myModal')
      modal.style.display = 'none'
    },
    AdicionarFuncionario (response) {
      var funcionario = {
        codigo: null,
        id: null,
        nome: null
      }
      this.formAddDe.funcionario.push(funcionario)
      this.formAddDe.quantidade++
    },
    removerfuncionario (response) {
      this.formAddDe.funcionario.splice(response, 1)
      this.formAddDe.quantidade--
    },
    getDespesasEncaminhadas () {
      loading.push()
      if (this.typeExpenses === 0) {
        SeveralExpansesRepository.GetAllDespesaEnviadas(this.company.companyID)
          .then(Response => {
            this.expensesHistorico = Response.data
            this.expensesHistorico.forEach(obj => {
              this.despesaEnvRecente = new Date(obj.datas)
            })

            // const dataConvertida = this.expensesHistorico.map(obj => new Date(obj.datas))
            // this.dataMaisRecente = dataConvertida.reduce((maisrecente, dataatual) => {
            //   return dataatual > maisrecente ? dataatual : maisrecente
            // })
            loading.pop()
          })
          .catch(() => loading.pop())
      } else {
        expensesmiscellaneousRepository.GetAllDespesaEnviadas(this.company.companyID)
          .then(Response => {
            this.expensesHistorico = Response.data
            this.expensesHistorico.forEach(obj => {
              this.despesaEnvRecente = new Date(obj.datas)
            })
            // const dataConvertida = this.expensesHistorico.map(obj => new Date(obj.datas))
            // this.dataMaisRecente = dataConvertida.reduce((maisrecente, dataatual) => {
            //   return dataatual > maisrecente ? dataatual : maisrecente
            // })
            loading.pop()
          })
          .catch(() => loading.pop())
      }
    },
    GetDespesasAprovadas () {
      if (this.typeExpenses === 0) {
        SeveralExpansesRepository.GetDespesasAprovadas()
          .then(Response => {
            this.dataMaisRecente = new Date(Response.data.dataRegistro)
          })
      } else {
        expensesmiscellaneousRepository.GetDespesasAprovadas()
          .then(Response => {
            this.dataMaisRecente = new Date(Response.data.dataRegistro)
          })
      }
    }
  }
}
